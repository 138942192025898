export const getPortalType = () => {
  let isCFD = false
  let isLI = false
  let isCALIBO = false
  if (process.env.NEXT_PUBLIC_SITE_ID === '1') {
    isLI = true
  }
  if (process.env.NEXT_PUBLIC_SITE_ID === '2') {
    isCFD = true
  }
  if (process.env.NEXT_PUBLIC_SITE_ID === '3') {
    isCALIBO = true
  }
  return { isCFD, isLI, isCALIBO }
}
export const getPortalName = () => {
  if (process.env.NEXT_PUBLIC_SITE_ID === '1') {
    return 'Lighting Illusions'
  }
  if (process.env.NEXT_PUBLIC_SITE_ID === '2') {
    return 'Ceiling Fans Direct'
  }
  if (process.env.NEXT_PUBLIC_SITE_ID === '3') {
    return 'Calibo'
  }
}
