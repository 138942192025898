import client from './utils/api'
import { getServerSession } from 'next-auth'
import CredentialsProvider from 'next-auth/providers/credentials'
import { getAccessToken } from './utils/token'
import FacebookProvider from 'next-auth/providers/facebook'
import GoogleProvider from 'next-auth/providers/google'
import AppleProvider from 'next-auth/providers/apple'
import handleSocialAuth from './utils/social-auth'
import { SignJWT } from 'jose'
import { createPrivateKey } from 'crypto'
import { getPortalType } from './utils/portal'

export async function generateSecret() {
  const { isCALIBO } = getPortalType()
  const privateKey = (process.env.NEXT_PRIVATE_APPLE_PRIVATE_KEY as string) ?? ''
  // console.log("PRIVATE KEYYEYYE", privateKey)
  if (isCALIBO || privateKey.length < 1) return ''
  // console.log("PRIVATEEEE", privateKey)
  const expiresIn = 86400 * 180
  const exp = Math.ceil(Date.now() / 1000) + expiresIn
  const expiresAt = Math.ceil(Date.now() / 1000) + expiresIn
  const expirationTime = exp ?? expiresAt
  const teamId = process.env.NEXT_PUBLIC_APPLE_TEAM_ID as string
  const keyId = process.env.NEXT_PUBLIC_APPLE_KEY_ID
  const clientId = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID as string
  console.log(teamId, keyId, clientId)
  const token = await new SignJWT({})
    .setAudience('https://appleid.apple.com')
    .setIssuer(teamId)
    .setIssuedAt()
    .setExpirationTime(expirationTime)
    .setSubject(clientId)
    .setProtectedHeader({ alg: 'ES256', kid: keyId, typ: 'JWT' })
    .sign(createPrivateKey(privateKey.replace(/\\n/gm, '\n')))
  return token
}

export const doGet = async <R>(
  routeName: string,
  params?: object,
  customParams?: object,
): Promise<R> => {
  const headers = await getHeaders()
  return client.api.get<R>(
    {
      name: routeName,
      options: { ...params, site_id: process.env.NEXT_PUBLIC_SITE_ID },
    },
    { headers, ...customParams },
  )
}

export const doPost = async <R>(
  routeName: string,
  params?: Record<string, any> | FormData,
  options = {},
  customHeaders = {},
) => {
  const headers = await getHeaders()
  const isFormData = params instanceof FormData
  if (isFormData && params) {
    params.append('site_id', `${process.env.NEXT_PUBLIC_SITE_ID}`)
  } else {
    headers['Content-Type'] = 'application/json'
    params = { ...params, site_id: process.env.NEXT_PUBLIC_SITE_ID }
  }
  return client.api.post<R>({ name: routeName, options }, params, {
    headers: { ...headers },
    ...customHeaders,
  })
}

export const doPut = () => {}

export const doDelete = () => {}

export const getHeaders = async (customHeaders = {}) => {
  const headers: HeadersInit = {
    //  'Cache-Control': 'no-cache',
    //  cache: 'no-store',
  }

  const session =
    typeof window === 'undefined'
      ? //  @ts-ignore
        await getServerSession(await authOptions())
      : getAccessToken()
      ? { user: { token: getAccessToken() } }
      : null
  if (session && session.user)
    // @ts-ignore
    headers['Authorization'] = `Bearer ${session.user.token}`

  for (const [key, value] of Object.entries(customHeaders)) {
    // @ts-ignore
    headers[key] = value
  }

  return headers
}

export const authOptions = async (
  req = {},
  userObject = {} as { name: { firstName: string; lastName: string } },
) => {
  const appleSecret = await generateSecret()
  return {
    providers: [
      CredentialsProvider({
        id: 'credentials',
        name: 'credentials',
        credentials: {},
        async authorize(credentials) {
          let data = await doPost('api.login', credentials)
          if (!data.success) {
            return null
          }
          const userData = data.data
          return {
            ...userData,
            token: data.authenticated_data,
          }
        },
      }),
      CredentialsProvider({
        id: 'as-customer',
        name: 'as-customer',
        credentials: {},
        async authorize(credentials) {
          let data = await doPost('api.login-as-customer', credentials)
          if (!data.success) {
            return null
          }
          const userData = data.data
          return {
            ...userData,
            token: data.authenticated_data,
          }
        },
      }),
      // login with only token
      CredentialsProvider({
        id: 'token',
        name: 'token',
        credentials: {},
        async authorize(credentials) {
          let data = await doPost('api.social-login', {
            ...credentials,
            linkable: true,
          })
          if (!data.success) {
            return null
          }
          const userData = data.data
          return {
            ...userData,
            token: data.authenticated_data,
          }
        },
      }),

      FacebookProvider({
        clientId: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID as string,
        clientSecret: process.env.NEXT_PRIVATE_FACEBOOK_CLIENT_SECRET as string,
      }),

      GoogleProvider({
        clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
        clientSecret: process.env.NEXT_PRIVATE_GOOGLE_CLIENT_SECRET as string,
      }),

      AppleProvider({
        clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID as string,
        clientSecret: appleSecret,
        profile(profile) {
          if (
            userObject &&
            userObject?.name?.firstName &&
            userObject?.name?.lastName
          ) {
            profile.name = `${userObject.name.firstName} ${userObject.name.lastName}`
          }

          return {
            id: profile.sub,
            name: profile.name ?? '',
            email: profile.email,
            image: null,
          }
        },
      }),
    ],
    callbacks: {
      //@ts-ignore
      async jwt({ token, user }) {
        return { ...token, ...user }
      },
      //@ts-ignore
      async session({ session, token, user }) {
        session.user = token
        return session
      },
      // @ts-ignore
      async signIn({ user, account, profile, email, credentials }) {
        if (
          account.provider == 'token' ||
          account.provider == 'credentials' ||
          account.provider == 'as-customer'
        )
          return true
        // @ts-ignore
        let authType = req.cookies.get('authType')?.value ?? 'register'
        const session_token = req.cookies.get('sessionToken')?.value ?? null
        if (!profile?.email) {
          const url = encodeURIComponent(
            `error=Email is required to login with ${account.provider}`,
          )
          return `/auth/${authType}?${url}`
        }
        let data = await handleSocialAuth(
          account,
          profile,
          authType,
          session_token,
        )
        if (!data.success) {
          const url = encodeURIComponent(`error=${data?.message}`)
          return `/auth/${authType}?${url}`
        }
        if (data?.linkable) {
          const email = profile?.email
          const provider = account?.provider
          const social_id = profile?.id ? profile.id : profile?.sub
          const url = encodeURIComponent(
            `linkable=true&email=${email}&provider=${provider}&social_id=${social_id}&accessToken=${account?.access_token}`,
          )
          return `/auth/${authType}?${url}`
        }
        user.token = data.authenticated_data

        return true
      },
    },
    secret: process.env.NEXTAUTH_SECRET,
    cookies: {
      pkceCodeVerifier: {
        name: 'next-auth.pkce.code_verifier',
        options: {
          httpOnly: true,
          sameSite: 'none',
          path: '/',
          secure: true,
        },
      },
    },
    pages: {
      signIn: '/auth/login',
    },
  }
}
