export function clsx(...args: Array<string | Record<string, boolean> | undefined | null | false>): string {
  return args
    .filter(arg => arg !== undefined && arg !== null && arg !== false)
    .map(arg => {
      if (typeof arg === "string") {
        return arg;
      } else if (typeof arg === "object" && arg !== null) {
        return Object.entries(arg)
          .filter(([key, value]) => value)
          .map(([key]) => key)
          .join(" ");
      }
      return undefined;
    })
    .join(" ");
}